body {
    overflow-y: auto;
}

/* START OF LOGIN AND REGISTER*/
.page {
    position: relative;
    display:flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);

}

.verlinkung {
    margin-bottom: 20px;
    text-align: center !important;
}

.login {
    width: 420px;
    height: 420px;
    transition: 1s ease-in-out all .3s;
    border-radius: 10px;
    z-index: 100;
    align-self: center;
}

.login.active {
    width: 800px;
    height: 1000px;
    max-height: 1200px;
    margin-bottom: 60px;
}

.auth-wrapper{
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth-wrapper p.main-alert{
    font-size: 1.75rem;
    color:white;
    text-align: center;
    margin: 1rem 1rem 3rem 1rem;
}

@media only screen and (min-width: 800px) {
    
    .auth-wrapper p.main-alert{
        font-size: 1.5rem;
        color:white;
        text-align: center;
        width: 80%;
        margin: 1rem auto 3rem auto;
    }
    
}

@media only screen and (min-width: 1200px) {
    
    .auth-wrapper p.main-alert{
        font-size: 1.75rem;
        color:white;
        text-align: center;
        width: 910px;
    }
    
}



.login.active.warning-active {
    height: 1000px;
}

.login.activeTeam {
    width: 720px;
    height: 1000px;
    max-height: 1100px;
    margin-bottom: 100px;
    margin-top: 200px;
}

.login.activeTeam.warning-active {
    height: 1100px;
}

.login .nav-login {
    position: relative;
    float: left;
    width: 100%;
    height: 70px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.login .nav-login a.user-login {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
    background-color: #028ccf;
    border-top-left-radius: 10px;
}

.change-password .login .nav-login a.user-login {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: auto;
}

.change-password .login .content-login .content-login-wrapper .input-wrapper {
    height: auto;
}

.change-password .login .content-login .content-login-wrapper .input-wrapper input {
    margin-bottom: 10px;
}

.change-password .login .content-login .content-login-wrapper .button-login {
    margin-top: 20px;
}

.login .nav-login a span {
    position: relative;
    float: left;
    width: 100%;
    /* height: 100%; */
    text-align: center;
    line-height: 63px;
    font-size: 20px;
    color: #fff;
}

.login .nav-login a.team-login {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
    background-color: #028ccf;
    border-top-right-radius: 10px;
}

.login .nav-login a.active span {
    color: #028ccf;
}

.login .nav-login a.active {
    background-color: #fff;
}

.login .content-login {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100% - 10px);
    overflow: hidden;
}

.login .content-login .content-login-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: 1.3s ease-in-out all;
    transform: translateY(-500%);
    background-color: #fff;
    padding: 0px 40px;
}

.confirm-user {
    position: relative;
    float: left;
    width: 100%;
    height: 100vh;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.confirm-user h1 {
    position: absolute;
    float: left;
    text-align:center;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.confirm-user a {
    position: absolute;
    float: left;
    width: 160px;
    height: 41px;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
    border: none;
    border-radius: 2em;
    background-color: #028ccf;
    color: #fff;
    font-size: 21px;
    outline: none;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
    text-decoration: none;
}

.login .content-login .content-login-wrapper.user-content.active {
    transform: translateY(0);
}

.login .content-login .content-login-wrapper.team-content.active {
    transform: translateY(0);
    padding-bottom: 30px;
}

.login .content-login .content-login-wrapper.forgot-content.active {
    transform: translateY(0);
}

.login .content-login .content-login-wrapper.forgot-content.active form {
    width: 100%;
}

.login .content-login .content-login-wrapper.forgot-content.active form input {
    width: 100%;
    transition: .3s linear all;
}

.sentEmail {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.sentEmail h1 {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    text-align: left;
    width: 100%;
    font-size: 30px;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.sentEmail h1:first-child {
    top: 35%;
}

.login .content-login .content-login-wrapper .login-logo {
    position: relative;
    float: left;
    width: 100%;
    height: 70px;
    margin: 20px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .content-login .content-login-wrapper .login-logo img {
    position: absolute;
    float: left;
    width: 40px;
    max-width: 40px;
    height: auto;
    left: 20%;
    transform: translateX(-20%)
}

.login .content-login .content-login-wrapper .login-logo img.register {
    left: 35%;
    transform: translateX(-50%)
}

.login .content-login .content-login-wrapper .login-logo h1 {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 13px;
    position: absolute;
    float: left;
    width: 70%;
    left: 80%;
    transform: translateX(-67%);
    /* margin-top: 15px; */
}

.login .content-login .content-login-wrapper .login-logo h1 span {
    font-weight: bold;
}

.login .content-login .content-login-wrapper .login-logo h1.register {
    left: 90%;
    transform: translateX(-73%);
}

.login .content-login .content-login-wrapper .input-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
}

.login .content-login .content-login-wrapper form {
    position: relative;
    float: left;
}

.login .content-login .content-login-wrapper .input-wrapper i {
    position: relative;
    float: left;
    background-color: #028ccf;
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 25px;
    text-align: center;
    line-height: 40px;
    margin-right: 2px;
}

.login .content-login .content-login-wrapper .input-wrapper input {
    position: relative;
    float: left;
    width: calc(100% - 42px);
    height: 40px;
    border: none;
    background-color: #028ccf;
    color: #fff;
    padding-left: 5px;
    transition: .5s linear all;
}

.login .content-login .content-login-wrapper .input-wrapper input::placeholder {
    color: #fff;
}

.login .content-login .content-login-wrapper > a {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    text-align: right;
    color: #028ccf;
    font-weight: 600;
}

.login .content-login .content-login-wrapper a:hover {
    text-decoration: underline;
}

.login .content-login .content-login-wrapper .button-login {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 30px;
}

.login .content-login .content-login-wrapper .button-login button {
    position: relative;
    float: left;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 2em;
    background-color: #028ccf;
    color: #fff;
    font-size: 23px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input[disabled] {
    opacity: .4;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input {
    position: relative;
    float: left;
    width: calc(50% - 20px);
    height: 40px;
    border: none;
    background-color: #028ccf;
    color: #fff;
    padding-left: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .addTeamMember {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .addTeamMembers {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    border: 2px solid #c6baba;
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 10px;
    border-radius: 15px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input::placeholder {
    color: #fff;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .left-part {
    position: relative;
    float: left;
    width: calc(50% - 20px);
    height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .right-part {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .left-part input {
    position: relative;
    float: left;
    width: 100%;
    height: 40px;
    border: none;
    background-color: #028ccf;
    color: #fff;
    padding-left: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .right-part input {
    position: relative;
    float: left;
    width: 100%;
    height: 40px;
    border: none;
    background-color: #028ccf;
    color: #fff;
    padding-left: 5px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.captcha {
    position: relative;
    float: left;
    width: calc(50% - 20px);
    height: auto;
    margin-left: 10px;
    margin-bottom: 10px;
    top: 100%;
    transform: translateY(-110%);
}

.right-part .checkbox-wrapper {
    position: relative;
    float: left;
    width: calc(50% - 20px);
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    top: 35%
}

.right-part .checkbox-wrapper .checkbox-terms {
    width: 100%
}

.right-part .checkbox-wrapper span.switchLabel {
    margin-right: 0;
    top: -20px;
    width: calc(100% - 90px);
    font-size: 13px;
    line-height: 20px;
}

.right-part .checkbox-wrapper span.switchLabel a {
    width: auto;
    float: none;
}

.login .content-login .content-login-wrapper .button-login.register {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 30px;
}

.login .content-login .content-login-wrapper .button-login.register button {
    position: relative;
    float: left;
    width: 50%;
    height: 40px;
    border: none;
    border-radius: 2em;
    background-color: #028ccf;
    color: #fff;
    font-size: 23px;
    left: 50%;
    transform: translateX(-50%);
}

.login .content-login .content-login-wrapper form.register-form-wrapper .chechboxTeam {
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    transform: translateX(50%);
}

.login .content-login .content-login-wrapper form.register-form-wrapper .chechboxTeamEmpty {
    /* position: relative;
    float: left; */
    width: 50%;
    height: 50px;
}

.tooltip-span {
    position: relative;
    float: right;
    color: #028ccf;
}

.tooltip.show {
    opacity: 1;
}

.tooltip-inner {
    background-color: #028ccf;
    max-width: 350px;
    height: auto;
    text-align: left;
    border: 3px solid #fff;
    border-radius: 0px;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #028ccf;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #028ccf;
    display: none;
}

/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
.switch {
    position: relative;
    display: inline-block;
    float: left;
    width: 60px;
    height: 34px;
    margin-right: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #028ccf;
}

.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 1px;
    bottom: 0px;
    background-color: #028ccf;
    border: 1.5px solid #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #028ccf;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #028ccf;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* END OF LOGIN AND REGISTER*/
.header {
    position: relative;
    float: left;
    width: 100%;
    height: 70px;
    background-color: #fff;
    z-index: 999;
    transform: rotate(-0.5deg);
}

.nav-helper {
    position: absolute;
    float: left;
    width: 100%;
    height: 10px;
    background-color: #028ccf;
    transform: rotate(-0.7deg);
    top: 65px;
    z-index: 99;
}

.header .container-fluid {
    height: 100%;
}

.header .header-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.header .header-wrapper .header-nav-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.header .header-wrapper .header-wrapper-center {
    position: relative;
    float: left;
    width: calc(100% - 40%);
    height: 100%;
}

.header .header-wrapper .header-wrapper-right a,
.header .header-wrapper .header-wrapper-center a {
    position: relative;
    float: left;
    width: 150px;
    height: 100%;
    text-align: center;
    padding-top: 37px;
    color: #111;
    font-size: 14px;
    font-weight: 550;
    text-decoration-line: none;
}

.header .header-wrapper .header-wrapper-right a {
    width: 50%;
}

.header .header-wrapper .header-wrapper-right {
    position: relative;
    float: left;
    width: 24%;
    height: 100%;
}

.nav-logo {
    position: relative;
    float: left;
    width: 16%;
    height: 70px;
    padding-top: 10px;
}

.nav-logo img {
    position: absolute;
    float: left;
    width: 40px;
    max-width: 40px;
    height: auto;
    left: 10%;
    transform: translateX(-10%)
}

.nav-logo h1 {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 13px;
    position: absolute;
    float: left;
    width: 70%;
    left: 70%;
    transform: translateX(-61%);
    margin-top: 12px;
}

.nav-logo h1 span {
    font-weight: bold
}



.content.question {
    min-height: calc(calc(100vh - 82px) - 30vh);
    height: auto;
}

.content .container-fluid {
    height: 100%;
}

.myAlert {
    position: absolute;
    z-index: 99999;
    height: 80px;
    max-height: 80px;
    width: 100%;
    background-color: #f5d986;
    float: left;
    margin-top: 64px;
    padding: 10px;
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
    -webkit-transition: .5s linear all;
    transition: .5s linear all;
    z-index: 1;
    opacity: 0;
    overflow-y: scroll;
    padding-top: 16px;
}

.myAlert.auth {
    margin-top: 0;
}

.myAlert.show {
    opacity: 1;
    transform: translateX(0px);
}

.myAlert span {
    position: relative;
    float: left;
    width: 95%;
    height: auto;
    text-align: center;
    color: #111;
    font-size: 18px;
    line-height: 35px;
}

.myAlert i {
    position: relative;
    float: right;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    top: 50%;
}

.content .middle-content {
    position: relative;
    float: left;
    width: 100%;
    height: 80%;
    padding: 0px 15px;
    /* perspective: 1200px; */
    top: 10px;
}

.content .home-wrapper .text-above {
    text-align: center;
    width: 50%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    z-index: 99999;
}

.content .middle-content a.content-box:hover {
    cursor: pointer;
}

.content .middle-content a.content-box {
    position: relative;
    float: left;
    width: calc(calc(100% / 3) - 10px);
    margin-right: 10px;
    height: 70%;
    background-color: #0083d3;
    top: 50%;
    padding: 20px;
    transform-style: preserve-3d;
    transform: translateY(-50%);
    cursor: auto;
    /* overflow-y: scroll;
    overflow-x: hidden; */
}

/* .content .middle-content a.content-box:first-child {
    transform: translateY(-50%) rotateY(40deg);
    margin-left: 55px;
}

.content .middle-content a.content-box:nth-child(2) {
    height: 62%;
}

.content .middle-content a.content-box:last-child {
    transform: translateY(-50%) rotateY(-40deg);
} */

.content .middle-content a.content-box .inside-box {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-right: 20px;
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper h1 {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 32px;
    margin-bottom: 30px;
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper span {
    position: relative;
    float: left;
    width: 100%;
    text-align: left;
    color: #fff;
    padding-bottom: 15px;
}

.footer {
    position: relative;
    float: left;
    width: 100%;
    height: 25vh;
    background-color: #0083d3;
}

.footer .footer-content {
    position: absolute;
    float: left;
    width: 100%;
    bottom: 10px;
}

.footer .copyright{
    color: white;
    font-size: 1rem;
    text-align: center;
}

.container-limit.my-limit {
    max-width: 900px;
}

.code-page-limit {
    position: relative;
    max-width: 900px;
    width: 100%;
    float: left;
    transform: translateX(-50%);
    left: 50%;
}

.footer .footer-content .footer-conten-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
}

.footer .footer-content .footer-conten-wrapper a {
    position: relative;
    font-size: 1rem;
    width: 10rem;
    text-align: center;
    color: #fff;
    margin: 0 0.5rem;
}

.footer .footer-content .footer-conten-wrapper a:first-child {
    text-align: right;
}

.footer .footer-content .footer-conten-wrapper a:last-child {
    text-align: left;
}

.footer .footer-content .footer-conten-wrapper a:hover{
    text-decoration:none;
    font-weight: bold;
}


@media only screen and (max-width: 1200px) {
    
    /* .footer .copyright{
        position: relative;
        width: 100%;
    } */
}


.womix__header-graphic {
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: white;
}

.womix__header-graphic svg {
    -webkit-filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
    filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 110%;
}

.womix__header-graphic svg.header_graphic-layer-1 {
    left: -15%;
    transform: translate(-1.212005%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-2 {
    left: -10%;
    transform: translate(12.093013%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-3 {
    left: -5%;
    transform: translate(-1.637303%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-1 polygon {
    fill: #15e4ae;
}

.womix__header-graphic svg.header_graphic-layer-2 polygon {
    fill: #0cbabe;
}

.womix__header-graphic svg.header_graphic-layer-3 polygon {
    fill: #0083d3;
}

/* Multiple Question Video */
.multiple-choise-video {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    min-height: 560px;
}

.progressBar {
    position: relative;
    float: left;
    width: 100%;
    height: 30px;
    margin-bottom: 30px;
}

.multiple-choise-video .video-wrapper,
.submit-idea-video .video-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 440px;
    margin-bottom: 20px;
}

.multiple-choise-video .video-wrapper iframe,
.submit-idea-video .video-wrapper iframe {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.multiple-choise-video .button-video,
.submit-idea-video .button-video {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
}

.multiple-choise-video .button-video button,
.submit-idea-video .button-video button {
    position: relative;
    width: 250px;
    height: 40px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 2em;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
}


.submit-idea-video .button-video button {
    transform: translateX(-50%);
    float: left;
    left: 50%;
}

.multiple-choise-video .button-video button:first-child {
    float: left;
    left: 0;
}

.multiple-choise-video .button-video button:last-child {
    float: right;
    right: 0;
}

.progress-table-bar {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 10px;
    /* border: 1px solid red; */
    border-radius: 11px;
    background: -webkit-gradient(linear, left top, left right, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: -webkit-linear-gradient(top, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    background: linear-gradient(to right, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    padding-top: 1px;
    padding-right: 1px;
    padding-left: 1px;
    height: 22px;
}

.progress {
    position: relative;
    float: left;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: #fff;
}

.progress-bar {
    background-color: #028ccf;
    border-radius: 10px;

}

.progress-bar span {
    color: #111;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 400;
}

.question-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.tittle {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
    padding: 10px 30px;
    margin-bottom: 20px;
}

.question-wrapper .tittle .title-iframe {
    position: relative;
    float: left;
    width: 50%;
}

.question-wrapper .tittle .title-iframe iframe {
    width: 300px;
    height: 300px;
}

.tittle>span {
    position: relative;
    float: left;
    width: 100%;
    white-space: pre-line;
}

.question-wrapper .tittle span.withFrame {
    width: 50%;
}

.question-wrapper .answers {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.question-wrapper .answers .inside-answers {
    position: relative;
    float: left;
    width: 100%;
    height: 70px;
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
    margin-bottom: 10px;
    transition: .7s linear all;
    cursor: pointer;
}

.question-wrapper .answers .inside-answers.iframe-answer {
    height: auto;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-letter {
    height: 300px;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content {
    height: 300px;
    padding: 0;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content iframe {
    position: absolute;
    float: left;
    width: 300px;
    height: 280px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

.question-wrapper .answers .inside-answers.iframe-answer:hover,
.question-wrapper .answers .inside-answers.iframe-answer.active {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
    margin-bottom: 10px;
    background-color: #0083d3;
}

.question-wrapper .answers .inside-answers input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}

.question-wrapper .answers .inside-answers .answer-letter {
    position: relative;
    float: left;
    width: 40px;
    height: 100%;
    background-color: #0083d3;
    transition: .7s linear all;
}

.question-wrapper .answers .inside-answers .answer-letter span {
    position: absolute;
    float: left;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.question-wrapper .answers .inside-answers .answer-content {
    position: relative;
    float: left;
    width: calc(100% - 45px);
    height: 100%;
    padding: 10px 30px;
}

.question-wrapper .answers .inside-answers .answer-content>span {
    position: relative;
    float: left;
    width: 100%;
    color: #111;
}

.question-wrapper .answers .inside-answers:hover,
.question-wrapper .answers .inside-answers.active {
    position: relative;
    float: left;
    width: 100%;
    height: 70px;
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
    margin-bottom: 10px;
    background-color: #0083d3;
}

.question-wrapper .answers .inside-answers:hover span,
.question-wrapper .answers .inside-answers.active span {
    color: #fff;
}

.question-info {
    position: relative;
    float: left;
    width: 100%;
    height: 25px;
}

.question-info span {
    position: relative;
    float: left;
    color: #0cbabe;
}

.question-info.error span {
    color: #e74c3c;
}

.question-info .tooltip-span {
    float: left !important;
}

#multiple-question .tooltip-inner {
    background-color: #0cbabe;
}

.button-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.button-wrapper button {
    position: relative;
    width: 200px;
    height: 30px;
    color: #fff;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    border-radius: 2em;
    border: none;
    line-height: 10px;
    cursor: pointer;
    outline: none;
}

.button-wrapper button.firstBtn {
    float: left;
}

.button-wrapper button.secondBtn {
    position: absolute;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

.button-wrapper button.thirdBtn {
    float: right;
}

.submitted-questions-label.error .tooltip-span,
.submitted-questions-label.error {
    color: #ff0000;
}

.nsewdrag.drag {
    width: 200px;
    height: 200px;
}

input.invalid {
    border: 1px solid red !important;
}

.login button[disabled],
.submit-idea button[disabled] {
    opacity: .4 !important;
}

.login button[disabled] {
    cursor: default !important;
}

.login button {
    cursor: pointer;
}

.bottom-part {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.bottom-part .row {
    margin: 0;
}

.code-submission-content {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.content.submission {
    min-height: calc(calc(100vh - 82px) - 30vh);
    height: auto;
}

.code-response {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    max-height: 300px;
    border: 3px solid #0083d3;
    border-left: 0px;
}

.ace-editor-wrapper .ace-monokai {
    border: 3px solid #0083d3;
}

.removePadding {
    padding: 0;
}

.code-response-wrapper.success {
    position: relative;
    float: left;
    width: 100%;
    background-color: #7bed9f;
    height: auto;
}

.code-response-wrapper.success p {
    color: #009432;
    font-size: 18px;
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 2px !important;
    margin-right: 10px;
}

.code-response-wrapper.error {
    position: relative;
    float: left;
    width: 100%;
    background-color: #ff7675;
    height: auto;
    border-bottom: 1px solid #d63031;
}

.code-response-wrapper.error p {
    color: #d63031;
    font-size: 18px;
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 2px !important;
    margin-right: 10px;
}

.code-response-wrapper.success i,
.code-response-wrapper.error i {
    padding-right: 10px;
}

.code-response-wrapper.error p .color-triangle {
    color: #ffd32a;
}

.code-response-wrapper.success p .color-square {
    color: #009432;
}

.code-response .response-first-part {
    position: relative;
    float: left;
    width: 100%;
    height: 80%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.code-response .response-second-part {
    position: relative;
    float: left;
    width: 100%;
}

.code-response .response-second-part button {
    position: relative;
    float: right;
    margin: 25px;
}

.content.submission .code-response-button:hover {
    cursor: pointer;
}

.content.submission .code-response-button {
    position: absolute;
    float: left;
    width: 6%;
    height: 13%;
    top: 100%;
    transform: translate(-75%, -100%);
    left: 65%;
    background-color: #0083d3;
    border: none;
    cursor: pointer;
    z-index: 9999;
}

.status-middle-content {
    position: relative;
    float: left;
    width: 100%;
    min-height: 500px;
    height: 500px;
}

.status-box {
    position: relative;
    float: left;
    width: calc(calc(100% / 3) - 10px);
    height: 300px;
    margin-right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
    cursor: auto !important;
}

.status-box:nth-child(2) {
    height: 370px;
    margin-top: 15px;
}

.status-box .status-title {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    background-color: #028ccf;
}

.status-box .status-title h1 {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 25px;
    line-height: 45px;
}

.status-box .status-date {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    padding: 0px 20px;
}

.status-box .status-date span {
    position: relative;
    float: left;
    width: 60%;
    color: #111;
    font-size: 15px;
    line-height: 65px;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
}

.status-box .status-date p {
    position: relative;
    float: left;
    width: 40%;
    height: 100%;
    line-height: 65px;
    color: #787878;
    font-size: 15px;
}

.status-box .status-content {
    position: relative;
    float: left;
    width: 100%;
    padding: 0px 20px;
    height: 130px;
}

.status-box .status-content span {
    position: relative;
    float: left;
    width: 100%;
    top: 30%;
    transform: translateY(-70%);
    font-size: 16px;
    font-weight: 500;
}

.status-box .status-content span:first-child {
    margin-bottom: 10px;
}

.status-box .status-content span.success {
    color: green;
}

.status-box .status-content span.partly {
    color: orange;
}

.status-box .status-content span.error {
    color: red;
}

.status-box .status-button {
    position: absolute;
    float: left;
    width: 100%;
    height: 70px;
    bottom: 0;
}

.status-box .status-points {
    position: relative;
    float: left;
    width: 100%;
    height: 138px;
    padding: 30px 20px 0px 20px;
}

.status-box .status-points span {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    text-align: center;
}

.status-box .status-points span:first-child {
    color: #787878;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
}

.status-box .status-points span:last-child {
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 35px;
    font-weight: 700;
}

.status-button-idee a:hover,
.status-box .status-button a:hover,
.completedBtn:hover {
    text-decoration: none !important;
}

.status-box .status-button a {
    position: absolute;
    float: left;
    width: 200px;
    height: 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 2em;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
}

.status-button-idee {
    position: relative;
    float: left;
    width: 100%;
}

.status-button-idee a {
    position: absolute;
    float: left;
    width: 270px;
    height: 35px;
    left: 50%;
    /* transform: translateX(-50%); */
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 2em;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    top: 0%;
    transform: translate(-50%, -80%);
    text-align: center;
    line-height: 35px;
}

.bestenliste {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.submit-idea .title,
.bestenliste .title {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
}

.submit-idea .title {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
}

.submit-idea .title span,
.submit-project .header span,
.submit-project .header.subheader span,
.bestenliste .title span {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.submit-project .header.subheader {
    margin-top: 10px;
    height: 50px;
    transform: rotate(0);
}

.submit-project .header.subheader span {
    text-align: left;
    font-size: 24px;
    /* height: 30px !important; */
}


.bestenliste .table-list {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.bestenliste .table-list .liste-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    margin-bottom: 15px;
    border: 1px solid #999;
    border-radius: 10px;
    /* box-shadow: 5px 5px 20px -8px rgba(0, 0, 0, 1); */
}

.bestenliste .table-list .liste-wrapper .number-list {
    position: relative;
    float: left;
    width: 6%;
    height: 100%;
    background-color: #0083d3;
}

.bestenliste .table-list .liste-wrapper .number-list span {
    position: absolute;
    float: left;
    color: #fff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 32px;
}
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

.bestenliste .table-list .liste-wrapper .title-team {
    position: relative;
    float: left;
    width: 75%;
    height: 100%;
    overflow: hidden;
}
.bestenliste .table-list .liste-wrapper .title-team span {
    position: absolute;
    float: left;
    font-size: 15px;
    line-height: 58px;
    padding-left: 10px;
}
.bestenliste .table-list .liste-wrapper .avatar-team {
    position: relative;
    float: left;
    width: 10%;
    height: 100%;
}

.bestenliste .table-list .liste-wrapper .avatar-team span {
    position: relative;
    float: right;
    width: 40px;
    height: auto;
    padding: 5px;
    border: 2px solid #f1eeee;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.bestenliste .table-list .liste-wrapper .avatar-team span canvas {
    position: relative;
    float: left;
    width: 100% !important;
    height: 100% !important;
}

.bestenliste .table-list .liste-wrapper .name-team {
    position: relative;
    float: left;
    width: 25%;
    height: 100%;
}
.bestenliste .table-list .liste-wrapper .name-team span {
    position: relative;
    float: left;
    width:100%;
    text-align: right;
    padding: 0 1rem 0 1rem;
    line-height: 58px;
    font-size: 15px;
    font-weight: bold;
    color: #615f5f;
}

.pagination-team
{
    position: relative;
    float: left;
    width: 100%;
}
.pagination-team .pagination
{
    position: relative;
    float: left;
    width: 100%;
    justify-content: center;
}
.button-team
{
    left: 50%;
    transform: translateX(-50%);
}
.comment-button
{
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%)
}

.no-margin-top{
    margin-top:0!important;
}

.button-zuruck
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 100px;
}
.button-zuruck a
{
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%)
}
.textAreaTeam 
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 10px;
    border: 2px solid #0083d3;
}
.blueColor
{
    color: #0083d3;
}
.textAreaTeam textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 150px;
    resize: none;
}
.viewTeam
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    border: 2px solid #0083d3;
    margin-top: 15px;
}
.viewTeam p
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    padding: 30px;
    margin-bottom: 40px;
}
.viewTeam .viewTeam-user
{
    position: absolute;
    float: left;
    width: 100%;
    height: auto;
    bottom: 0;
    background-color: lightgray;
    padding: 7px 0px;
}
.viewTeam .viewTeam-user .viewTeam-userImg canvas
{
    position: relative;
    float: left;
    width: 30px !important;
    height: 30px !important;
    margin-right: 20px;
    border: 1px solid #f1eeee;
    padding: 4px;
    border-radius: 50%;
}
.viewTeam .viewTeam-user span:first-child
{
    position: relative;
    float: left;
    text-align: right;
    width: 60%;
    padding-right: 20px;
}
.viewTeam .viewTeam-user span:nth-child(3)
{
    position: relative;
    float: left;
    width: auto;
    margin-right: 20px;
    font-weight: bold;
    color: #615f5f;
}
.viewTeam .viewTeam-user span:last-child
{
    position: relative;
    float: right;
    margin-right: 20px;
}
.viewTeam-userImg
{
    position: relative;
    float: left;
    width: 5%;
}
.user-date
{
    position: relative;
    float: left;
    width: 35%;
    border-bottom: 2px solid lightgray;
}
.user-date span:first-child
{
    text-align: left !important;
    font-weight: bold;
}
.cursor-pointer
{
    cursor: pointer;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
.bestenliste .table-list .liste-wrapper .name-list {
    position: relative;
    float: left;
    width: 40%;
    height: 100%;
    padding-left: 30px;
}

.bestenliste .table-list .liste-wrapper .name-list span {
    position: relative;
    float: left;
    text-align: left;
    line-height: 58px;
    font-size: 22px;
}

.bestenliste .table-list .liste-wrapper .points-list {
    position: relative;
    float: left;
    width: 20%;
    height: 100%;
}

.bestenliste .table-list .liste-wrapper .points-list span {
    position: relative;
    float: left;
    text-align: left;
    line-height: 58px;
    font-size: 22px;
}

.bestenliste .table-list .liste-wrapper .avatar-list {
    position: relative;
    float: left;
    width: 34%;
    height: 100%;
    padding-right: 60px;
    border-right: 1px solid #bebebe;
}

.bestenliste .table-list .liste-wrapper .avatar-list span {
    position: relative;
    float: right;
    width: 60px;
    height: 100%;
    padding: 5px;
}

.bestenliste .table-list .liste-wrapper .avatar-list span canvas {
    position: relative;
    float: left;
    width: 100% !important;
    height: 100% !important;
}

.table-list .border-wrapper {
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
}

.hideLeftMenuBtn {
    display: none;
}

.submit-idea .beschreibung,
.submit-idea .herkunft,
.submit-idea .datei,
.submit-idea .thema {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 25px;
}

.form-input{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 25px;
}

.form-input span.labelDesc,.form-input.labelDesc {
    color: #0083d3;
    font-size: 18px!important;
    font-weight: 600!important;
    position: relative;
    float: left;
    width: 100%;
} 


.submit-idea .herkunft textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 6.25rem;
    border: 2px solid #999;
    resize: none;
    padding: 0.5rem;
}

.submit-idea .beschreibung textarea,.form-input.beschreibung textarea,.form-input textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 12.5rem;
    border: 2px solid #999;
    padding: 0.5rem;
    resize: none;
}

.submit-idea input {
    
    position: relative;
    float: left;
    width: 100%;
    height: 2.5rem;
    border: 2px solid #999;
    padding: 0.5rem;
}

.datei input,
.submit-idea .datei input {
    position: relative;
    float: left;
    width: 70%;
    height: 2.5rem;
    border: 2px solid #999;
    padding: 0.5rem;
}

.submit-idea .datei button.myButton {
    position: relative;
    float: left;
    width: 30%;
    height: 30px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
}

form span.labelDesc,
.submit-idea form span.labelDesc {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    float: left;
    width: 100%;
}


form button.myButton,
.submit-idea form button.myButton {
    position: relative;
    float: left;
    width: 200px;
    height: 35px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    margin-top: 30px;
}

form button.myButton:disabled {
    cursor:auto;
}

.submitedIdea {
    position: relative;
    float: left;
    width: 100%;
    height: 300px;
}

.submitedIdea .submited-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    /* background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text; */
    color: black;
}











.submit-project .ziel,
.submit-project .motivation,
.submit-project .titel,
.submit-project .anwendung,
.submit-project .datensatz,
.submit-project .vorbereitung,
.submit-project .dnn,
.submit-project .evaluation,
.submit-project .produkt,
.submit-project .komponenten,
.submit-project .anleitung,
.submit-project .probleme,
.submit-project .potential,
.submit-project .schwachstelle,
.submit-project .zukunft {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.submit-project .ziel textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .motivation textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}

.submit-project .anwendung textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .datensatz textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .vorbereitung textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .dnn textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .evaluation textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .produkt textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .komponenten textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .anleitung textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 200px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .probleme textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .potential textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .schwachstelle textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 100px;
    border: 2px solid #0083d3;
    resize: none;
}
.submit-project .zukunft textarea {
    position: relative;
    float: left;
    width: 100%;
    height: 200px;
    border: 2px solid #0083d3;
    resize: none;
}

.submit-project input, .submit-project .dropdown,.submit-idea .dropdown {
    position: relative;
    float: left;
    width: 100%;
    height: 30px;
    border: 2px solid #0083d3;
}

.dropdown{
    background:white;
}


.submit-project .datei input {
    position: relative;
    float: left;
    width: 70%;
    height: 30px;
    border: 2px solid #0083d3;
}

.submit-project .datei button.myButton {
    position: relative;
    float: left;
    width: 30%;
    height: 30px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
}

.submit-project form span.labelDesc {
    color: #0083d3;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    float: left;
    width: 100%;
}

.submit-project form span.labelDesc.metaData {
   margin-top: 15px;
}

.submit-project form button.myButton {
    display: block;
    float: left;
    width: 200px;
    height: 35px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 2em;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    margin-right: 20px;
    margin-top: 30px;
}

.submit-project form button.myButton:disabled {
    cursor: no-drop;
    opacity:0.4;
}

.submit-project form .buttons-div{
    display: grid;
    grid-template-rows: auto auto auto;
    width: 100%;
}

.submitedProject {
    position: relative;
    float: left;
    width: 100%;
    height: 300px;
}

.imp-info{
    color: red;
    text-align: center;
    width: 100;
    margin: 20px 0px;
    font-weight: 600;
    font-size: 1.5rem;
    
}

.in-progress{
    width: 100%;
    text-align: center;
    color:#0083d3 ;
    font-size: 1.5rem;
}

.submitedProject .submited-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.multiple-choise-video .title {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
}

.multiple-choise-video .title span {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.completedBtn {
    position: relative;
    float: left;
    width: 250px;
    height: 40px;
    background-color: #0083d3;
    color: #fff;
    border: none;
    border-radius: 2em;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
}

.switchLabel {
    float: left;
    position: relative;
    margin-right: 30px;
    line-height: 34px;
}

.left-menu {
    position: absolute;
    float: left;
    width: 40%;
    height: 100vh;
    margin-top: 70px;
    background-color: #fff;
    z-index: 9;
    padding: 50px;
    transform: translateX(-1500px);
    /* transition: .4s linear all; */
}

.left-menu .title {
    position: relative;
    float: left;
    width: 100%;
    height: 30px;
    margin-bottom: 30px;
}

.left-menu .title span {
    position: relative;
    float: left;
    width: auto;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
}

.left-menu .title img {
    position: relative;
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.left-menu .questions {
    position: relative;
    float: left;
    width: 100%;
    /* min-height: 500px; */
    height: auto;
    max-height: 83%;
    overflow: auto;
    padding-right: 10px;
}

.left-menu .questions .question-inside {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
}

.left-menu .questions .question-inside:hover {
    cursor: pointer;
}

.left-menu .questions .question-inside.active {
    margin-left: 3px;
    border: 2px solid #0083d3;
    width: calc(100% - 3px);
}

.left-menu .questions .question-inside.hide {
    opacity: .4;
}

.left-menu .questions .question-inside .number-question {
    position: relative;
    float: left;
    width: 10%;
    height: 100%;
    background-color: #0083d3;
}

.left-menu .questions .question-inside .number-question span {
    position: absolute;
    float: left;
    font-size: 30px;
    color: #fff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.left-menu .questions .question-inside.video .number-question {
    width: 20%;
}

.left-menu .questions .question-inside.video .number-question span {
    height: 100%;
    width: 100%;
}

.left-menu .questions .question-inside.video .number-question span img {
    width: 100%;
    height: 100%;
}

.left-menu .questions .question-inside.video .content-question {
    width: 80%;
}

.left-menu .questions .question-inside .content-question {
    position: relative;
    float: left;
    width: 90%;
    height: 100%;
    padding: 5px 10px;
    overflow: hidden;
}

.left-menu .questions .question-inside .content-question span {
    position: relative;
    float: left;
    width: 100%;
    color: #111;
}

.left-menu.show {
    /* transform: translateX(0); */
    animation: showLeftMenu 1s forwards;
}

.left-menu.hide {
    /* transform: translateX(0); */
    animation: hideLeftMenu 1s forwards;
}

.overflowHidden {
    overflow: hidden;
}

@keyframes showLeftMenu {
    0% {
        transform: translateX(-1500px);
        height: 1500px;
    }

    30% {
        transform: translateX(-382px) rotate(-24deg);
        height: 1500px;

    }

    97% {
        transform: translateX(0);
        /* height: 100vh !important; */
        box-shadow: none;
    }

    100% {
        transform: translateX(0);
        /* height: 100vh !important; */
        box-shadow: 0px 0px 60px -10px rgba(0, 0, 0, 1);
    }
}

@keyframes hideLeftMenu {
    0% {
        transform: translateX(0);
        height: 1500px;
        box-shadow: none;
    }

    30% {
        transform: translateX(-382px) rotate(-24deg);
        height: 1500px;
        box-shadow: none;
    }

    100% {
        transform: translateX(-1500px);
        height: 0px;
        box-shadow: none;
    }

}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 0;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.not-found {
    position: relative;
    float: left;
    width: 100%;
    min-height: calc(calc(100vh - 66px) - 30vh)
}

.not-found h1 {
    position: absolute;
    float: left;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #333;
}

.checkbox-wrapper {
    position: relative;
    float: left;
    width: 100%;
}

.DayPickerInput {
    width: calc(50% - 20px);
}

.DayPickerInput input {
    width: 100% !important;
}

.DayPickerInput-Overlay {
    top: 40px;
}

@media only screen and (max-width: 1024px) {
    .nav-logo {
        width: 25%;
    }

    .header .header-wrapper .header-wrapper-center {
        width: 50%;
    }

    .header .header-wrapper .header-wrapper-right {
        width: 25%;
    }
    .header .header-wrapper .header-wrapper-center a
    {
        width: 120px;
    }
}

@media only screen and (min-width: 1900px) {
    .container-fluid.home-wrapper {
        padding: 0px 25px !important;
    }
}

.content-login.overflow {
    overflow: unset;
}

@media only screen and (max-height: 735px) {
    .status-middle-content {
        height: 50vh;
        min-height: 50vh;
    }
}

.bestenliste-message {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.nav-logo a {
    color: black;
}

.long-text {
    line-height: 30px !important;
    width: 100% !important;
    margin-top: 20px;
}

.submit-idea-video {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    min-height: 560px;
}

.warning-wrapper{
    position:relative;
    width: 100%;
}

.olderWarning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    position: relative;
    padding: 10px;
    margin: 10px;
}

.LinesEllipsis
{
    position: absolute;
    float: left;
    font-size: 15px;
    line-height: 58px;
    padding-left: 10px;
} 


.radio-description{
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.radio-wrapper {
    position: relative;
    float: left;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    top: 35%;
    display: flex;
    width: 100%;
}

.radio-label{
    flex: 1 1 100%;
    text-align: center;
}

.radio-input {
  height: auto !important;
}

.team-finding-advice-2 {
    display: inline-block;
    text-align: center !important;
    color: #15e4ae;
    position: relative;
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
}

.team-finding-advice-1 {
    display: inline-block;
    text-align: center !important;
    color: #15e4ae;
    position: relative;
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
}

.team-finding-advice-2 > span {
    display: block;
}

.bold-red{
    color: red;
    font-weight: bold;
    text-align: center;
}

.bold-warning{
    color: #dd6a34;
    font-weight: bold;
    text-align: center;
}

/* styling registration color, background */
.reg-style-wrapper .form-group input{
    background-color: #028ccf;
    color: #fff;
}

.reg-style-wrapper .form-group input::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }

.reg-style-wrapper .form-group input[disabled] {
    opacity: 0.4;
  }

/* Overlay mapper */
.overlay-panel-container{
    position: fixed;
    top:0px;
    left:0px;
    height: 100%;
    width: 100%;
    background-color: #00000055;
    z-index: 99999;

}

.overlay-panel{
    /* position: relative; */
    background-color: white;
    width: 80%;
    max-height: 80vh;
    margin: 10vh auto;
    z-index: 100000;
    padding: 2rem;
    overflow-y: auto;
}

.overlay-panel.competition-home{
    position: relative;
    max-width: 1200px;
    padding: 2rem 0;
}


.overlay-panel .close{
    position: absolute;
    right: 10vw;
    top: 10vh;
    height: 3rem;
    width: 3rem;
    cursor:pointer;
    text-align: center;
    vertical-align: middle;
    font-size: 2rem;
    line-height: 3rem;
}

.overlay-panel.leave-team-popup .close,
.overlay-panel.competition-home .close{
    right: 0;
    top:0;
}

.overlay-panel .close::after{
    content: "X";
}


.overlay-panel .close:hover{
    background-color: #00000022;
}

.overlay-panel .definition-header{
    font-size: 2rem;
    font-weight: bold;
}

.overlay-panel .section{
    padding: 1.5rem 0 1rem 0;
    font-size: 1.5rem;

}

.overlay-panel.leave-team-popup{
    position: relative;
    max-width: 40rem;
    width: 80%;
}

.overlay-panel.competition-home .section{
    max-height: calc(80vh - 7rem);
    overflow: auto;
    padding: 0 2rem 1.5rem 2rem;
    margin-top:1.5rem
}

.overlay-panel.competition-home .section p{
    font-size: 1.5rem;
}

.overlay-panel.competition-home .section p span.points{
    font-weight: bold;
    margin-left: 2rem;
}

.overlay-panel .section span.section-title{
    font-weight: bold;
}

.overlay-panel .section>p{
    margin:0;
}

/* Increase and inherit width */
.react-datepicker__input-container {
    width: inherit;
  }
  
.react-datepicker-wrapper {
width: 100%;
}

/* App banner css definition */

.app-banner-wrapper{
    position: relative;
    /* float: left; */
    width: calc(100% - 4rem);
    height: 5rem;
    margin: 1rem 2rem;
    z-index: 999;
}

.banner-grid{
    width: 100%;
    display: grid;
    grid-template-columns: 15rem auto 3rem ;
}


.banner-logo {
    position: relative;
    float: left;
    width: 15rem;
    height: 55px;
    margin: 15px 0 15px 0;
    border-right: 2px solid #999;
}

.banner-logo img {
    position: relative;
    float: left;
    width: 40px;
    max-width: 40px;
    height: auto;
    margin-right: 1rem;
    /* left: 10%;
    transform: translateX(-10%) */
}

.banner-logo h1 {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 13px;
    position: relative;
    float: left;
    width: 70%;
    margin-top: 12px;
}

.banner-logo h1 span {
    font-weight: bold
}

.banner-logo a {
    color: black;
}


@media only screen and (max-width: 1024px) {
    .banner-logo {
        /* width: 25%; */
    }
}

.menu-right{
    grid-column: 3;
    cursor:pointer;
    height: 3rem;
    width: 3rem;
    float: right;
    margin: 1rem 0rem;
}

.app-banner-dropdown{
    height: auto;
    width: 18rem;
    position: absolute;
    right: 2.25rem;
    top: 4.5rem;
    padding: 1rem 1rem 0rem 1rem;
    background: #EEE;
    z-index: 100000;
}

.app-banner-overlay{

}

.app-banner-dropdown li{
    margin:0.5rem 0.5rem;
    border-bottom: 0.5px solid #333;
}

.app-banner-dropdown a{
    display: block;
    color: #000;
    font-size: 1rem;
    padding: 0.25rem 0rem;
    text-decoration: none;
}

.app-banner-dropdown a:hover{
font-weight: 600;
   color: #000;
   text-decoration: none;
}

.app-banner-dropdown .logout{
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.breadcrumb-main{
    height: 100%;
    width: calc(100% - 5rem);
    padding: 1.2rem 0 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
}


.breadcrumb-main a{
    float: left;
    width: auto;
    margin: .5rem 0 .5rem .5rem;
    color: #333;
    font-weight: 700;
}

.breadcrumb-main a:hover{
    color: #333;
    text-decoration: none;

}

.breadcrumb-main span {
    margin-right: 0.4rem;
}

.question_icon{
left: auto;
color: #007bff;
float: right;
margin-top: -35px;
margin-right: 8px;
position:relative;
}

.vl {
    border-left: 2px solid black;
    height: 50px;
    float: right;
    max-height: 75px;
  }